<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabPayMethodClick">
       METODE BAYAR
      </v-tab>


      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FPayMethodTable ref="refTabPayMethodClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs>

  </v-card>
</template>

<script>
import FPayMethodTable from "../../components/sales/pay-method/FPayMethodTable";

export default {
  components: { FPayMethodTable },
  data() {
    return {
      firstTabTitle: 'ACC',
      firstTabIcon: 'mdi-credit-card'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabPayMethodClick(){
      try {
        this.$refs.refTabPayMethodClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },


  }
}
</script>

<style scoped>
</style>